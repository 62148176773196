<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-row>
      <div class="text-center">
        <base-material-snackbar
          v-model="ajoutOK"
          :type="colorSnackbarSuccess"
          v-bind="{
            [parsedDirection[0]]: true,
            [parsedDirection[1]]: true
          }"
        >
          <span class="font-weight-bold"> La jantes a bien été ajouter</span>
        </base-material-snackbar>

        <base-material-snackbar
          v-model="ajoutKO"
          :type="colorSnackbarError"
          v-bind="{
            [parsedDirection[0]]: true,
            [parsedDirection[1]]: true
          }"
        >
          <span class="font-weight-bold"> La jantes n'a pas été ajouter</span>
        </base-material-snackbar>
      </div>
    </v-row>
    <base-material-card
      icon="mdi-clipboard-text"
      title="Add"
      class="px-5 py-3"
    >
      <v-form enctype="multipart/form-data">
        <v-container class="py-0">
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="jante.reference"
                label="Référence"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="jante.marque"
                label="Marque"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="jante.modele"
                label="Modèle"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="jante.couleur"
                label="Couleur"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="jante.entraxe"
                label="Entraxe"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="jante.offset"
                type="number"
                label="Offset"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="jante.largeur"
                type="number"
                label="Largeur"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="jante.diametre"
                type="number"
                label="Diamètre"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="jante.alesage"
                type="number"
                label="Alesage"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="jante.prix"
                type="number"
                label="Prix"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="jante.stock"
                type="number"
                label="Stock"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <input
                type="file"
                name="image1"
                accept="image/*"
                class="input-file"
                @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
              >
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-btn
                color="success"
                class="mr-4"
                @click="ajouterJante (jante)"
              >
                Ajouter Jante
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </base-material-card>

    <div class="py-3" />
  </v-container>
</template>

<script>
  // Utilities
  import axios from 'axios'
  import VueAxios from 'vue-axios'
  import Vue from 'vue'
  import {
    mapState,
  } from 'vuex'

  Vue.use(VueAxios, axios)

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      items: [],
      itemToShow: true,
      jante: [],
      colorSnackbarSuccess: 'success',
      colorSnackbarError: 'error',
      direction: 'top right',
      ajoutOK: false,
      ajoutKO: false,
    }),
    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      parsedDirection () {
        return this.direction.split(' ')
      },
      profile () {
        return {
          avatar: true,
          title: this.$t('site_title'),
        }
      },
    },
    created () {
    },
    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
      ajouterJante () {
        console.log(typeof this.jante)
        Vue.axios.post('https://api.espace-jantes.com/addJantes', {
          params:
            {
              janteMarque: this.jante.marque,
              janteModele: this.jante.modele,
              janteReference: this.jante.reference,
              janteAlesage: this.jante.alesage,
              janteLargeur: this.jante.largeur,
              jantePrix: this.jante.prix,
              janteDiametre: this.jante.diametre,
              janteOffset: this.jante.offset,
              janteCouleur: this.jante.couleur,
              janteEntraxe: this.jante.entraxe,
              jantesStock: this.jante.stock,
            },
        })
          .then((response) => {
            if (response.data.etat) {
              if (response.data.etat) {
                this.ajoutOK = true
                this.colorSnackbarSuccess = 'success'
              } else {
                this.ajoutKO = true
                this.colorSnackbarError = 'error'
              }
            } else {
              if (response.data.etat) {
                this.ajoutOK = true
                this.colorSnackbarSuccess = 'success'
              } else {
                this.ajoutKO = true
                this.colorSnackbarError = 'error'
              }
            }
            var self = this
            setTimeout(function () { self.$router.push({ path: '/product/list-jantes' }) }, 2000)
          })
          .catch(error => console.log(error))
      },
      GoDetailsJantes: function (item) {
        this.$router.push({ name: 'JantesDetails', params: { infoJantes: item } })
      },
      filesChange (formdata) {
        const url = 'https://api.espace-jantes.com/addPromotion'
        return axios.post(url, formdata)
          // get data
          .then(x => x.data)
          // add url field
          .then(x => x.map(img => Object.assign({}, img, { url: 'https://api.espace-jantes.com/addPromotion' })))
      },
    },
  }
</script>
